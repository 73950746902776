import React, { Component } from "react";
import "./ErrorList.css";
import {
  ListGroup,
  Row,
  Col,
  Image,
  Modal,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Sidebar, Header, ErrorListViewDetails } from "..";
import ErrorListSearch from "../ErrorList/ErrorListSearch";
import EndpointConstant from "./../../constants/EndpointConstant";
import MessageConstant from "./../../constants/MessageConstant";
import utils from "./../../utils";
import PopupModels from "./../PopupModels/PopupModels";
import RoleConstant from "../../constants/RoleConstant";
import Moment from "react-moment";
import moment from "moment";
import { v4 as uuidV4 } from "uuid";
import { ProgressBar } from "primereact/progressbar";

// import ErrorDetails from '../ErrorDetails/ErrorDetails';

/**
 * A class that can return some html
 */
class ErrorList extends Component {
  /**
   *renders the ui
   *@return {Object} .
   */
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      errorsSort: [],
      errorDetails: [],
      show: false,
      currentPage: 1,
      todosPerPage: 10,
      upperPageBound: 3,
      lowerPageBound: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 3,
      deleteConfirmation: false,
      id: "",
      errorDeleteSuccess: false,
      searchValues: null,
      datasetIds: [],
      searchData: null,
      sId: "",
      downloadRequest: "Processing",
      showDataSync: false,
      extractValue: 0,
      extractSuccess: false,
    };
    this.setShow = this.setShow.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.firstclick = this.firstclick.bind(this);
    this.lastClick = this.lastClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    this.searchResults = this.searchResults.bind(this);
    this.onSortError = this.onSortError.bind(this);
    this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this);
    this.deleteError = this.deleteError.bind(this);
    this.clearSearchData = this.clearSearchData.bind(this);
    this.downloadError = this.downloadError.bind(this);
    this.getdatasetIds = this.getdatasetIds.bind(this);
    this.setSearchData = this.setSearchData.bind(this);

    this.getdownloadScorecardData = this.getdownloadScorecardData.bind(this);
    this.getdownloadScorecardDataFromDB =
      this.getdownloadScorecardDataFromDB.bind(this);
    this.hideDataSyncOnCancel = this.hideDataSyncOnCancel.bind(this);
    this.handleExtractSuccessClose = this.handleExtractSuccessClose.bind(this);
  }

  //
  getdownloadScorecardData(downloadErrorValue) {
    let data = {};
    let payload = {};
    console.log(data, " data value goes here...");
    if (!this.state.searchValues) {
      payload = {
        datasetId: this.state.datasetIds,
      };
      payload.sId = uuidV4();
      this.setState({
        sId: payload.sId,
        datasetId: this.state.datasetIds,
        downloadRequest: "Processing",
      });
      console.log("Json value", JSON.stringify(payload));
      console.log("SId value ", this.state.sId);

      downloadErrorValue === "downloadError"
        ? (payload.downloadError = true)
        : downloadErrorValue === "downloadErrorSummary"
        ? (payload.downloadErrorSummary = true)
        : downloadErrorValue === "downloadBusinessCritical"
        ? (payload.downloadBusinessCritical = true)
        : "";
    } else {
      downloadErrorValue === "downloadError"
        ? (payload.downloadError = true)
        : downloadErrorValue === "downloadErrorSummary"
        ? (payload.downloadErrorSummary = true)
        : downloadErrorValue === "downloadBusinessCritical"
        ? (payload.downloadBusinessCritical = true)
        : "";
      if (this.state.searchData.error.errorId !== "") {
        payload.errorId = this.state.searchData.error.errorId;
      }
      if (this.state.searchData.error.subcaseNumber !== "") {
        payload.subcaseNumber = this.state.searchData.error.subcaseNumber;
      }
      if (this.state.searchData.error.associatedTeam.length !== 0) {
        payload.associatedTeam = this.state.searchData.error.associatedTeam;
      }
      if (this.state.searchData.error.errorType !== "") {
        payload.errorType = this.state.searchData.error.errorType;
      }
      if (this.state.searchData.selected.length !== 0) {
        payload.datasetId = this.state.searchData.error.selected;
      }
      if (this.state.searchData.error.country.length !== 0) {
        payload.interactionCountry = this.state.searchData.error.country;
      }
      if (
        this.state.searchData.error.modifiedAt !== "" &&
        this.state.searchData.error.modifiedAt !== null
      ) {
        let date = this.state.searchData.error.modifiedAt;
        let month = date.getMonth() + 1;
        //console.log(this.state.error.modifiedAt.getMonth()+1,this.state.error.modifiedAt.getFullYear(),this.state.error.modifiedAt.getDate())
        payload.modifiedAt =
          date.getFullYear() + "-" + month + "-" + date.getDate();
      }
      if (this.state.searchData.error.status.length !== 0) {
        payload.status = this.state.searchData.error.status;
      }
    }
    console.log(payload, " get Filtered Error Data");

    utils.makeRequestInBackground(
      this,
      EndpointConstant.FILTER_ERRORS,
      payload,
      function (result) {
        console.log(" result ", JSON.stringify(result));
      }
    );
  }
  //
  getdownloadScorecardDataFromDB() {
    let downloadErrorValue = this.state.downloadErrorValue;
    let payload = {
      sId: this.state.sId,
      downloadRequest: this.state.downloadRequest,
    };
    downloadErrorValue === "downloadError"
      ? (payload.downloadError = true)
      : downloadErrorValue === "downloadErrorSummary"
      ? (payload.downloadErrorSummary = true)
      : downloadErrorValue === "downloadBusinessCritical"
      ? (payload.downloadBusinessCritical = true)
      : "";
    let self = this;
    utils.makeRequestInBackground(
      this,
      EndpointConstant.FILTER_ERRORS_STATUS,
      payload,
      function (result) {
        console.log(" result ", JSON.stringify(result));
        if (result.downloadRequest == "Completed") {
          self.setState({ downloadRequest: "Completed" });

          window.open(result.url, "Download");
        } else {
          self.setState({ downloadRequest: "Processing" });
        }
      }
    );
  }
  //
  downloadError(event, downloadErrorValue) {
    event.preventDefault();
    this.setState({
      showDataSync: true,
      downloadErrorValue: downloadErrorValue,
    });
    this.getdownloadScorecardData(downloadErrorValue);
    this.interval = setInterval(() => {
      let val = this.state.extractValue;
      val += Math.floor(Math.random() * 5) + 1;
      if (val >= 99) {
        val = 99;
      }
      this.setState({
        extractValue: val,
      });
      this.state.downloadRequest === "Processing"
        ? this.getdownloadScorecardDataFromDB()
        : "";

      if (this.state.downloadRequest == "Completed") {
        this.hideDataSyncOnCancel();
        this.setState({
          extractSuccess: true,
          extractValue: 100,
          extractResponseMsg: MessageConstant.DOWNLOADED_INTERACTION,
          extractResponseFlagIcon: "success",
        });
        clearInterval(this.interval);
        this.interval = null;
        console.log(" extractSuccess  ", this.state.extractSuccess);
      }
    }, 5000);
  }
  handleExtractSuccessClose() {
    this.setState({
      extractSuccess: false,
      showDataSync: false,
      extractValue: 0,
      downloadUrl: "",
      sId: "",
      downloadRequest: "",
    });
  }

  hideDataSyncOnCancel() {
    this.setState({
      extractSuccess: false,
      showDataSync: false,
      extractValue: 0,
      downloadUrl: "",
      sId: "",
      downloadRequest: "",
      downloadErrorValue: null,
    });
    clearInterval(this.interval);
  }

  setSearchData(data) {
    //console.log(Object.values(data).length, 'obj length')
    if (
      data.subcaseNumber === "" &&
      data.associatedTeam.length === 0 &&
      data.selected.length === 0 &&
      data.errorType.length === 0 &&
      data.country.length === 0 &&
      data.channel.length === 0 &&
      data.status.length === 0 &&
      data.modifiedAt.length === 0 &&
      data.errorId === ""
    ) {
      this.setState({ searchData: null });
    } else {
      this.setState({ searchData: data });
    }
  }
  getdatasetIds(ids) {
    //console.log("ids",ids);
    let listIds = [];
    if (ids.length > 0) {
      listIds = ids.map((dataset) => {
        return dataset.id;
      });
    }
    //console.log("listIds",listIds);
    //if(this.state.searchdata.selected.length == 0) {
    this.setState({
      datasetIds: listIds,
    });
    //}
  }
  componentDidMount() {
    this.getErrorList();
  }
  handleDeleteClose() {
    this.setState({ errorDeleteSuccess: false });
  }
  handleDeleteConfirmation(id) {
    this.setState({
      deleteConfirmation: !this.state.deleteConfirmation,
      id: id,
    });
  }
  deleteError() {
    let self = this;
    let data = {
      id: this.state.id,
    };
    utils.makeRequest(
      this,
      EndpointConstant.DELETE_ERROR,
      data,
      function (result) {
        if (result.error === "false" || result.error === false) {
          const errors = result.errorList;
          let errorsCheck = [];
          self.loading = false;
          if (self.state.searchValues != null) {
            for (var j = 0; j < self.state.errors.length; j++) {
              let errorIdCheck = self.state.errors[j];
              let stateCheck = self.state.errors;
              if (errorIdCheck.id == self.state.id) {
                stateCheck.splice(j, 1);
                // delete errorIdCheck.id;
                errorsCheck = stateCheck;
                self.setState({
                  errors: errorsCheck,
                  errorsSort: errors,
                  deleteConfirmation: false,
                  errorDeleteSuccess: true,
                });
              }
            }
          } else {
            self.setState({
              errors: errors,
              errorsSort: errors,
              deleteConfirmation: false,
              errorDeleteSuccess: true,
            });
          }
        }
      }
    );
  }
  handleClick(event) {
    let listid = Number(event.target.innerText);
    this.setState({
      currentPage: listid,
    });
    this.setPrevAndNextBtnClass(listid);
  }

  setPrevAndNextBtnClass(listid) {
    let totalPage = Math.ceil(
      this.state.errors.length / this.state.todosPerPage
    );
    this.setState({ isNextBtnActive: "disabled" });
    this.setState({ isPrevBtnActive: "disabled" });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: "" });
    } else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
    } else if (totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
      this.setState({ isPrevBtnActive: "" });
    }
  }
  firstclick() {
    this.setState({ currentPage: 1 });
    this.setState({ upperPageBound: 3 });
    this.setState({ lowerPageBound: 0 });
    this.setPrevAndNextBtnClass(1);
  }
  lastClick() {
    let totalpages = Math.ceil(
      this.state.errors.length / this.state.todosPerPage
    );
    this.setState({ currentPage: totalpages });
    this.setState({ upperPageBound: totalpages });
    this.setState({ lowerPageBound: totalpages - 3 });
    this.setPrevAndNextBtnClass(totalpages);
  }
  btnIncrementClick() {
    this.setState({
      upperPageBound: this.state.upperPageBound + this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
    });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnDecrementClick() {
    this.setState({
      upperPageBound: this.state.upperPageBound - this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
    });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnPrevClick() {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - 1 });
      this.setState({
        lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnNextClick() {
    if (this.state.currentPage + 1 > this.state.upperPageBound) {
      this.setState({
        upperPageBound: this.state.upperPageBound + this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }

  getErrorList() {
    let data = {};
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.ERRORS_LIST,
      data,
      function (result) {
        if (result.error === "false" || result.error === false) {
          const errors = result.errorList;
          // let errors = [];
          self.loading = false;
          self.setState({ errors: errors, errorsSort: errors }, () => {
            let totalpages = Math.ceil(
              self.state.errors.length / self.state.todosPerPage
            );
            if (totalpages === self.state.currentPage) {
              self.setState({ isNextBtnActive: "disabled" });
            }
          });
        }
      }
    );
  }
  searchResults(searchData) {
    // debugger
    this.setState(
      {
        errors: searchData,
        currentPage: 1,
        upperPageBound: 3,
        lowerPageBound: 0,
      },
      () => this.setPrevAndNextBtnClass(this.state.currentPage)
    );
  }
  clearSearchData(searchValues) {
    this.setState({ searchValues: searchValues });
  }
  viewErrorDetails(id, datasetName) {
    //  debugger
    let self = this;
    let data = {
      id: id,
    };
    utils.makeRequest(
      this,
      EndpointConstant.ERROR_DETAILS,
      data,
      function (result) {
        const errors = result.errorsDetails[0];
        self.loading = false;
        errors.datasetName = datasetName;
        self.setState({ errorDetails: errors });
        self.setState({ show: true });
      }
    );
  }
  setShow() {
    this.setState({ show: false });
  }

  /*
   * on Sort functionality
   */
  sortError(prop, sortId) {
    return (a, b) => {
      if (sortId.sortId === "sort") {
        return a[prop].toString().localeCompare(b[prop].toString());
      } else if (sortId.sortId === "sort-up") {
        return b[prop].toString().localeCompare(a[prop].toString());
      } else if (sortId.sortId === "sort-down") {
        return a;
      }
    };
  }
  onSortError(data, e) {
    e.preventDefault();
    //console.log(e.target.outerHTML);
    let sortId = e.target.id;
    // let className = e.target.className;
    if (sortId === "sort") {
      e.target.id = "sort-up";
      e.target.className = "fas fa-sort-up";
    } else if (sortId === "sort-up") {
      e.target.id = "sort-down";
      e.target.className = "fas fa-sort-down";
    } else if (sortId === "sort-down") {
      e.target.id = "sort";
      e.target.className = "fas fa-sort";
    }
    const { errorsSort } = this.state;
    switch (data) {
      case "errorid":
        // let errorId = [...errorsSort].sort(this.sortError('errorId', { sortId: sortId }));
        this.setState({
          errors: [...errorsSort].sort(
            this.sortError("errorId", { sortId: sortId })
          ),
        });
        break;
      case "caseno":
        // let interactionId = [...errorsSort].sort(this.sortError('interactionId', { sortId: sortId }));
        this.setState({
          errors: [...errorsSort].sort(
            this.sortError("interactionId", { sortId: sortId })
          ),
        });
        break;
      case "errortype":
        // let errorType = [...errorsSort].sort(this.sortError('errorType', { sortId: sortId }));
        this.setState({
          errors: [...errorsSort].sort(
            this.sortError("errorType", { sortId: sortId })
          ),
        });
        break;
      case "country":
        // let country = [...errorsSort].sort(this.sortError('country', { sortId: sortId }));
        this.setState({
          errors: [...errorsSort].sort(
            this.sortError("country", { sortId: sortId })
          ),
        });
        break;
      case "modifiedon":
        // let modifiedOn = [...errorsSort].sort(this.sortError('modifiedOn', { sortId: sortId }));
        this.setState({
          errors: [...errorsSort].sort(
            this.sortError("modifiedOn", { sortId: sortId })
          ),
        });
        break;
      case "associatedTeam":
        // let associatedTeam = [...errorsSort].sort(this.sortError('associatedTeam', { sortId: sortId }));
        this.setState({
          errors: [...errorsSort].sort(
            this.sortError("associatedTeam", { sortId: sortId })
          ),
        });
        break;
      case "status":
        // let status = [...errorsSort].sort(this.sortError('status', { sortId: sortId }));
        this.setState({
          errors: [...errorsSort].sort(
            this.sortError("status", { sortId: sortId })
          ),
        });
        break;
      default:
        break;
    }
  }
  colorChangeStatus(status) {
    const styles = {
      resolved: "#0F9D58",
      inProgress: "#F4B400",
      notAnError: "#04B2D9",
    };
    switch (status) {
      case "2":
        return { color: styles.resolved };
      case "0":
        return { color: styles.inProgress };
      case "1":
        return { color: styles.notAnError };
      default:
        return {};
    }
  }

  add3Dots(string, limit) {
    var dots = "...";
    if (string && string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }

  render() {
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    //console.log(this.state.errorDetails, 'this.state.errorDetails')
    const errors = this.state.errors;
    // console.log(errors[0],'eer')
    const {
      currentPage,
      todosPerPage,
      upperPageBound,
      lowerPageBound,
      isPrevBtnActive,
      isNextBtnActive,
    } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos =
      errors && errors.slice(indexOfFirstTodo, indexOfLastTodo);
    const errorList =
      currentTodos &&
      currentTodos.map((data) => {
        return (
          <tr key={data.id}>
            <td>{data.errorId.toUpperCase()}</td>
            <td>{data.subcaseNumber.toUpperCase()}</td>
            <td title={data.datasetName}>
              {this.add3Dots(data.datasetName, 8)}
            </td>
            <td title={siteinfo.ErrorTypes[data.errorType]}>
              {this.add3Dots(siteinfo.ErrorTypes[data.errorType], 10)}
            </td>
            {/*  <td>{siteinfo.CallCenterTeam[data.associatedTeam]}</td> */}
            <td title={data.associatedTeam}>
              {this.add3Dots(data.associatedTeam, 14)}
            </td>
            <td title={data.country}>{this.add3Dots(data.country, 7)}</td>
            <td>
              {moment.unix(data.modifiedAt).utc().format("MM/DD/YYYY hh:mm A")}
            </td>
            {/* <td>12/02/2019</td>  */}
            <td style={this.colorChangeStatus(data.status)}>
              {siteinfo.ErrorStatus[data.status]}
            </td>
            <td>
              <a
                className="view-details"
                onClick={() => this.viewErrorDetails(data.id, data.datasetName)}
              >
                <i className="fa fa-eye"></i>&nbsp;View Details
              </a>
              {utils.getSessionItem("role") === RoleConstant.QualityMonitor && (
                <a
                  className="delete-error"
                  onClick={() => this.handleDeleteConfirmation(data.id)}
                >
                  <Image
                    src="/Images/ico-delete.png"
                    className="image-padding"
                  ></Image>
                  &nbsp;Delete
                </a>
              )}
            </td>
          </tr>
        );
      });

    const pageNumbers = [];
    if (errors) {
      for (let i = 1; i <= Math.ceil(errors.length / todosPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      if (number === 1 && currentPage === 1) {
        return (
          <ListGroup.Item
            key={number}
            className="active list-item-padding"
            id={number}
            onClick={this.handleClick}
          >
            <a id={number}>{number}</a>
          </ListGroup.Item>
        );
      } else if (number < upperPageBound + 1 && number > lowerPageBound) {
        return (
          <ListGroup.Item
            key={number}
            className={
              number === currentPage
                ? "active list-item-padding"
                : "list-item-padding"
            }
            id={number}
            onClick={this.handleClick}
          >
            <a id={number}>{number}</a>
          </ListGroup.Item>
        );
      }
      return null;
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = (
        <ListGroup.Item
          className="list-item-padding"
          onClick={this.btnIncrementClick}
        >
          <a> &hellip; </a>
        </ListGroup.Item>
      );
    }
    let pageDecrementBtn = null;

    if (lowerPageBound >= 1) {
      pageDecrementBtn = (
        <ListGroup.Item
          className="list-item-padding"
          onClick={this.btnDecrementClick}
        >
          <a> &hellip; </a>
        </ListGroup.Item>
      );
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === "disabled") {
      renderPrevBtn = (
        <ListGroup.Item className={isPrevBtnActive}>
          <span id="btnPrev">
            {" "}
            <Image
              src="/Images/ico-arrow-left-disabled.png"
              className="arrow-left-right"
            ></Image>{" "}
          </span>
        </ListGroup.Item>
      );
    } else {
      renderPrevBtn = (
        <ListGroup.Item className={isPrevBtnActive} onClick={this.btnPrevClick}>
          <a id="btnPrev">
            <Image
              src="/Images/ico-arrow-left.png"
              className="arrow-left-right"
            ></Image>{" "}
          </a>
        </ListGroup.Item>
      );
    }
    let renderNextBtn = null;
    if (isNextBtnActive === "disabled") {
      renderNextBtn = (
        <ListGroup.Item className={isNextBtnActive}>
          <span id="btnNext">
            {" "}
            <Image
              src="/Images/ico-arrow-right-disabled.png"
              className="arrow-left-right"
            ></Image>{" "}
          </span>
        </ListGroup.Item>
      );
    } else {
      renderNextBtn = (
        <ListGroup.Item className={isNextBtnActive} onClick={this.btnNextClick}>
          <a id="btnNext">
            {" "}
            <Image
              src="/Images/ico-arrow-right.png"
              className="arrow-left-right"
            ></Image>{" "}
          </a>
        </ListGroup.Item>
      );
    }
    let frstpage = null;
    if (isPrevBtnActive === "disabled") {
      frstpage = (
        <ListGroup.Item className={isPrevBtnActive}>
          <span id="btnNext">
            <Image
              src="/Images/ico-arrow-left-end-disabled.png"
              className="arrow-left-right-end"
            ></Image>{" "}
          </span>
        </ListGroup.Item>
      );
    } else {
      frstpage = (
        <ListGroup.Item className={isPrevBtnActive} onClick={this.firstclick}>
          <a>
            {" "}
            <Image
              src="/Images/ico-arrow-left-end.png"
              className="arrow-left-right-end"
            ></Image>{" "}
          </a>
        </ListGroup.Item>
      );
    }
    let lastpage = null;
    if (isNextBtnActive === "disabled") {
      lastpage = (
        <ListGroup.Item className={isNextBtnActive}>
          <span id="btnNext">
            {" "}
            <Image
              src="/Images/ico-arrow-right-end-disabled.png"
              className="arrow-left-right-end"
            ></Image>{" "}
          </span>
        </ListGroup.Item>
      );
    } else {
      lastpage = (
        <ListGroup.Item className={isNextBtnActive} onClick={this.lastClick}>
          <a>
            <Image
              src="/Images/ico-arrow-right-end.png"
              className="arrow-left-right-end"
            ></Image>{" "}
          </a>
        </ListGroup.Item>
      );
    }

    return (
      <div>
        <Header ref={`header`} />
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="menu-content">
          {this.state.show ? (
            ""
          ) : (
            <ErrorListSearch
              getSearchResult={this.searchResults}
              getSearchClearData={this.clearSearchData}
              datasetIds={this.getdatasetIds}
              setSearchResult={this.setSearchData}
              searchData={this.state.searchData}
            />
          )}
          {this.state.show ? (
            <ErrorListViewDetails
              errorDetails={this.state.errorDetails}
              hideViewDetails={this.setShow}
              error="old"
            />
          ) : (
            ""
          )}
          {this.state.show ? (
            ""
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Error ID</th>
                  <th>Sub-Case Number</th>
                  <th>Dataset</th>
                  <th>Error Type</th>
                  <th>Associated Team</th>
                  <th>Country</th>
                  <th>Modified On</th>
                  <th>Error Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{errorList}</tbody>
            </table>
          )}
          {this.state.show ? (
            ""
          ) : (
            <Row className="padding-top">
              <Col>
                <ListGroup className="pageNumbers">
                  {frstpage}
                  {/* {renderfirst} */}
                  {renderPrevBtn}
                  {pageDecrementBtn}
                  {renderPageNumbers}
                  {pageIncrementBtn}
                  {renderNextBtn}
                  {lastpage}
                </ListGroup>
              </Col>

              <Dropdown>
                <Dropdown.Toggle
                  className="download-subcases button-color btn btn-primary"
                  id="dropdown-basic"
                >
                  Download
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => this.downloadError(e, "downloadError")}
                  >
                    Error Report
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) =>
                      this.downloadError(e, "downloadErrorSummary")
                    }
                  >
                    Error Summary Report
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) =>
                      this.downloadError(e, "downloadBusinessCritical")
                    }
                  >
                    Business Critical Report
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* <Button variant="secondary" disabled={this.state.errors.length===0} className='download-subcases button-color btn btn-primary' onClick={this.downloadError}>Download Errors</Button> */}
              {/* <Col ><span className='verticalAlign'>Page {this.state.currentPage} of {Math.ceil(this.state.interactions.length / this.state.todosPerPage)} ({errors.length} Records)</span></Col> */}
            </Row>
          )}
        </div>
        <Modal
          dialogClassName="deletionModal"
          show={this.state.deleteConfirmation}
          onHide={this.handleDeleteConfirmation}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header className="confirmation-header">
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="confirmation-body">
            <div>{MessageConstant.DELETING_ERROR}</div>
          </Modal.Body>
          <Modal.Footer className="confirmation-footer">
            <Button onClick={this.handleDeleteConfirmation} className="clear">
              No
            </Button>
            <Button active onClick={this.deleteError}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <PopupModels
          showPopup={this.state.errorDeleteSuccess}
          iconFlag="success"
          message={MessageConstant.ERROR_DELETED}
          handleClosePopup={this.handleDeleteClose.bind(this)}
        />
        <Modal
          show={this.state.showDataSync}
          onHide={this.hideDataSyncOnCancel}
          className="dataset-extract-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>DOWNLOAD ERROR LIST</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              style={{
                height: "10px",
                borderRadius: "5px",
                border: "1px solid #0063C3",
                background: "#F2F2F2",
              }}
              showValue={false}
              value={this.state.extractValue}
            />
            <p className="progressMsg">Downloading is in progress...</p>
            <p className="progressMsg">{this.state.extractValue}% completed</p>
          </Modal.Body>
          <Modal.Footer className="confirm-popup-header">
            <Button
              variant="primary"
              className="clear "
              onClick={this.hideDataSyncOnCancel}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <PopupModels
          showPopup={this.state.extractSuccess}
          iconFlag={this.state.extractResponseFlagIcon}
          message={this.state.extractResponseMsg}
          handleClosePopup={this.handleExtractSuccessClose}
        />
      </div>
    );
  }
}

export default ErrorList;
